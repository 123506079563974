import { Flex, Spinner, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLoadMap } from '@ubirider/pick-component-library'
import { ScheduleModal } from '../../modals/ScheduleModal'
import config from '../../config/configLoader'
import { getNetworkInfo, agencyID } from '../../api/requests'
import { decodePolylinePath, getBoundsFromPolylinePath } from '../../hooks/getPathBounds'
import { getAlertsForRoute, getStopsForRoute } from '../../hooks/getMapInfo'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { customError, customLog } from '../../helpers/log'
import { CardsList } from './components/CardsList'
import { MapArea } from './components/MapArea'
import { CardsDrawer } from './components/CardsDrawer'
import { FormProvider, useForm } from 'react-hook-form'

function NetworkSchedules() {
  const navigate = useNavigate()
  const location = useLocation()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { r, p, s } = useParams()
  const { isLoaded: isMapLoaded } = useLoadMap()

  const methods = useForm({
    defaultValues: {
      pattern: parseInt(p) || 0,
      search: null,
    },
  })

  const selectedPattern = methods.watch('pattern')

  const [loading, setLoading] = useState(false)
  const [showMobileSearchBar, toggleMobileSearchBar] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [alerts, setAlerts] = useState(null)

  const [allBounds, setAllBounds] = useState(null)
  const [bounds, setBounds] = useState(null)

  const [network, setNetwork] = useState(null)
  const [filteredRoutes, setFilteredRoutes] = useState([])
  const [filteredStops, setFilteredStops] = useState([])
  const [selectedNetwork, selectNetwork] = useState(null)
  const [selectedRoute, selectRoute] = useState(parseInt(r))
  const [selectedStop, selectStop] = useState(parseInt(s))
  const [stopSequence, setStops] = useState(null)
  const [stopRealTime, setStopRealTime] = useState(null)

  const [hoveredStop, setHoveredStop] = useState('')
  const [hoveredRoute, setHoveredRoute] = useState('')

  useEffect(() => {
    const getNetworkData = async () => {
      setLoading(true)

      const alertsData = await getNetworkInfo('alerts/getAlerts', { agencyID })
      const networkData = await getNetworkInfo('network/getNetwork', { agencyID })
      customLog(networkData)

      if (networkData) {
        networkData.routes?.sort((a, b) => parseInt(a.shortName) - parseInt(b.shortName))
        setAlerts(alertsData)
        setNetwork(networkData)
        let pathCoords = []
        for (const route of networkData.routes) {
          const pathValue = decodePolylinePath(route.shape)
          const index = networkData.routes.indexOf(route)
          pathCoords.push(pathValue)
          if (index === networkData.routes.length - 1) {
            const boundsValue = getBoundsFromPolylinePath(pathCoords)
            setAllBounds(boundsValue)
          }
        }
      }

      if (!window.location.pathname.includes('/route') && !window.location.pathname.includes('/stop')) {
        setLoading(false)
      }
    }

    const getSelectedNetworkData = async () => {
      const routeValue = await getNetworkInfo('network/getRouteDetails', {
        agencyID,
        routeID: selectedRoute || parseInt(r),
      })

      if (routeValue || routeValue.length) {
        const shapeID = Object.values(routeValue?.patterns?.entities)[selectedPattern || parseInt(p) || 0]?.shapeID
        const pathValue = decodePolylinePath(routeValue.shapes[shapeID])
        const boundsValue = getBoundsFromPolylinePath([pathValue])
        const realtimeValue = await getNetworkInfo('network/getRouteRealTimeData', {
          agencyID,
          routeID: selectedRoute || parseInt(r),
        })
        const stopValue = getStopsForRoute(network.stops, selectedRoute || parseInt(r))
        const alertValue = getAlertsForRoute(Object.values(alerts.entities), selectedRoute || parseInt(r), stopValue)
        const pattern = Object.values(routeValue.patterns?.entities)[parseInt(p) || 0]
        const stopValues = pattern?.stopSequence
        const filteredStops = stopValue.filter(item => stopValues.includes(item.id))
        const orderedStops = stopValues.map(id => filteredStops.find(stop => stop.id === id))
        setSearchValue(
          routeValue.name.replace(/([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g, match => match.charAt(0) + match.slice(1).toLowerCase())
        )
        selectNetwork({ route: routeValue, stops: stopValue, alerts: alertValue, real_time: realtimeValue })
        setStops(orderedStops)
        setBounds(boundsValue)
        selectStop(null)
      }
      setLoading(false)
    }

    const getSelectedStopNetworkData = async () => {
      const firstRoute = JSON.parse(Object.values(network.stops).find(stop => stop.id === parseInt(s)).routeIDs)[0]
      const routeValue = await getNetworkInfo('network/getRouteDetails', { agencyID, routeID: firstRoute })
      const shapeID = Object.values(routeValue.patterns?.entities)[selectedPattern || parseInt(p) || 0]?.shapeID
      const pathValue = decodePolylinePath(routeValue.shapes[shapeID])
      const boundsValue = getBoundsFromPolylinePath([pathValue])
      const realtimeValue = await getNetworkInfo('network/getRouteRealTimeData', { agencyID, routeID: firstRoute })
      const stopValue = getStopsForRoute(network.stops, firstRoute)
      const alertValue = getAlertsForRoute(Object.values(alerts.entities), firstRoute, stopValue)
      const pattern = Object.values(routeValue.patterns?.entities)[parseInt(p) || 0]
      const stopValues = pattern?.stopSequence
      const filteredStops = stopValue.filter(item => stopValues.includes(item.id))
      const orderedStops = stopValues.map(id => filteredStops.find(stop => stop.id === id))
      setSearchValue(
        stopValue
          .find(stop => selectedStop && stop.id === selectedStop)
          ?.name.replace(/([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g, match => match.charAt(0) + match.slice(1).toLowerCase())
      )
      selectNetwork({ route: routeValue, stops: stopValue, alerts: alertValue, real_time: realtimeValue })
      setStops(orderedStops)
      setBounds(boundsValue)
      setLoading(false)
    }

    if (!network) getNetworkData()
    else {
      if (window.location.pathname === '/network-and-schedules') {
        onClose()
        selectNetwork(null)
        selectRoute(null)
        selectStop(null)
        setBounds(null)
        setSearchValue('')
        methods.reset({
          search: '',
          pattern: parseInt(p) || 0,
        })
      } else if (window.location.pathname.includes('/route')) {
        selectRoute(parseInt(r))
        methods.reset({
          pattern: parseInt(p),
        })
        if (selectedNetwork && selectedNetwork?.route.id === parseInt(r)) {
          setSearchValue(
            selectedNetwork.route.name.replace(
              /([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g,
              match => match.charAt(0) + match.slice(1).toLowerCase()
            )
          )
          const pattern = Object.values(selectedNetwork.route.patterns?.entities)[parseInt(p) || 0]
          const stopValues = pattern?.stopSequence
          const filteredStops = selectedNetwork.stops.filter(item => stopValues.includes(item.id))
          const orderedStops = stopValues.map(id => filteredStops.find(stop => stop.id === id))
          setStops(orderedStops)
          selectStop(null)
        } else {
          getSelectedNetworkData()
        }
      } else if (window.location.pathname.includes('/stop')) {
        if (selectedNetwork) {
          selectStop(parseInt(s))
          setHoveredStop(parseInt(s))
          setSearchValue(
            network.stops
              .find(stop => stop.id === parseInt(s))
              .name.replace(/([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g, match => match.charAt(0) + match.slice(1).toLowerCase())
          )
        } else {
          getSelectedStopNetworkData()
        }
      }
    }
  }, [location.pathname, network])

  // Loop for RealTime data fetch every 10 seconds
  useEffect(() => {
    const fetchRealTime = async () => {
      try {
        if (selectedNetwork && selectedRoute === selectedNetwork.route.id && searchValue !== '') {
          const realtimeValue = await getNetworkInfo('network/getRouteRealTimeData', {
            agencyID,
            routeID: selectedRoute,
          })
          selectNetwork({ ...selectedNetwork, real_time: realtimeValue })
        }
      } catch (error) {
        customError(error)
      }
    }

    let timeoutId
    let fetchRealTimeRef = fetchRealTime

    const clearTimeoutAndReset = () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      fetchRealTimeRef = fetchRealTime
    }

    if (selectedNetwork) {
      clearTimeoutAndReset()
      timeoutId = setTimeout(async () => {
        await fetchRealTimeRef()
      }, 10000)
    }

    return () => {
      clearTimeoutAndReset()
    }
  }, [selectedNetwork])

  useEffect(() => {
    if (network && !selectedRoute) {
      const filteredDataRoutes = network.routes.filter(item => {
        const name = item.name?.toLowerCase()
        const shortName = item.shortName?.toLowerCase()
        return name.includes(searchValue?.toLowerCase()) || shortName.includes(searchValue?.toLowerCase())
      })

      const filteredDataStops = network.stops.filter(item => {
        const name = item.name?.toLowerCase()
        return name.includes(searchValue?.toLowerCase())
      })

      setFilteredRoutes(filteredDataRoutes)
      setFilteredStops(filteredDataStops)
    } else if (searchValue === '' && network) {
      selectRoute(null)
      selectStop(null)
      selectNetwork(null)
      methods.reset({
        pattern: parseInt(p) || 0,
      })
      setBounds(null)
      setHoveredStop(null)
      setHoveredRoute(null)
      toggleMobileSearchBar(false)
      navigate('/network-and-schedules')
    }
  }, [searchValue])

  const updateSelectedRouteThenStop = async (routeID, stopID, stopName) => {
    const routeValue = await getNetworkInfo('network/getRouteDetails', { agencyID, routeID })
    const shapeID = Object.values(routeValue.patterns?.entities)[selectedPattern || 0]?.shapeID
    const pathValue = decodePolylinePath(routeValue.shapes[shapeID])
    const boundsValue = getBoundsFromPolylinePath([pathValue])
    const realtimeRouteValue = await getNetworkInfo('network/getRouteRealTimeData', { agencyID, routeID })
    const stopValue = getStopsForRoute(network.stops, routeID)
    const alertValue = getAlertsForRoute(Object.values(alerts.entities), routeID, stopValue)
    const realtimeStopValue = await getNetworkInfo('network/getStopRealTimeData', { agencyID, stopID })
    selectNetwork({ route: routeValue, stops: stopValue, alerts: alertValue, real_time: realtimeRouteValue })
    selectRoute(routeID)
    selectStop(stopID)
    setBounds(boundsValue)
    setStopRealTime(realtimeStopValue)
    setSearchValue(stopName)
  }

  const updateMobileSearchBar = value => {
    toggleMobileSearchBar(value)
  }

  const updateSelectedRoute = value => {
    selectRoute(value)
  }

  const updateSelectedStop = value => {
    selectStop(value)
  }

  const updateSearchValue = value => {
    setSearchValue(value)
  }

  const handleHoverRoute = text => {
    setHoveredRoute(text)
  }

  const handleHoverStop = stopID => {
    setHoveredStop(stopID)
  }

  if (loading || !isMapLoaded) {
    return (
      <Flex mt='-70px' align='center' justify='center' minH='calc(100vh)' minW='calc(100vw)'>
        <Spinner thickness='10px' color={config.mainColor} width='100px' height='100px' />
      </Flex>
    )
  } else {
    return (
      <>
        <FormProvider {...methods}>
          <Flex display={{ sm: 'flex', lg: 'flex' }} flex='1' overflow='hidden' h='calc(100vh - 70px)'>
            <CardsList
              network={network}
              selectedNetwork={selectedNetwork}
              selectedRoute={selectedRoute}
              selectedStop={selectedStop}
              selectedPattern={selectedPattern}
              stopSequence={stopSequence}
              stopRealTime={stopRealTime}
              searchValue={searchValue}
              filteredRoutes={filteredRoutes}
              filteredStops={filteredStops}
              updateSearchValue={updateSearchValue}
              hoveredRoute={hoveredRoute}
              handleHoverRoute={handleHoverRoute}
              hoveredStop={hoveredStop}
              handleHoverStop={handleHoverStop}
              updateSelectedRoute={updateSelectedRoute}
              updateSelectedStop={updateSelectedStop}
              updateSelectedRouteThenStop={updateSelectedRouteThenStop}
              alerts={alerts}
              onOpenSchedules={onOpen}
            />

            <Flex
              flex={selectedNetwork ? { sm: '1', lg: '7.5' } : { sm: '0', lg: '7.5' }}
              w='100%'
              h={
                selectedNetwork
                  ? { sm: 'calc(100vh - 190px)', lg: 'calc(100vh - 70px)' }
                  : { sm: 'calc(100vh - 70px)', lg: 'calc(100vh - 70px)' }
              }
            >
              <MapArea
                allBounds={allBounds}
                bounds={bounds}
                network={network}
                selectedNetwork={selectedNetwork}
                selectedRoute={selectedRoute}
                selectedStop={selectedStop}
                selectedPattern={selectedPattern}
                stopSequence={stopSequence}
                hoveredRoute={hoveredRoute}
                handleHoverRoute={handleHoverRoute}
                hoveredStop={hoveredStop}
                handleHoverStop={handleHoverStop}
                alerts={alerts}
              />

              <CardsDrawer
                network={network}
                selectedNetwork={selectedNetwork}
                selectedRoute={selectedRoute}
                selectedStop={selectedStop}
                selectedPattern={selectedPattern}
                stopSequence={stopSequence}
                stopRealTime={stopRealTime}
                searchValue={searchValue}
                updateSearchValue={updateSearchValue}
                filteredRoutes={filteredRoutes}
                filteredStops={filteredStops}
                hoveredRoute={hoveredRoute}
                handleHoverRoute={handleHoverRoute}
                hoveredStop={hoveredStop}
                handleHoverStop={handleHoverStop}
                updateSelectedRoute={updateSelectedRoute}
                updateSelectedStop={updateSelectedStop}
                showMobileSearchBar={showMobileSearchBar}
                updateMobileSearchBar={updateMobileSearchBar}
                isOpenSchedules={isOpen}
                onOpenSchedules={onOpen}
              />
            </Flex>
          </Flex>
        </FormProvider>
        <ScheduleModal
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          network={selectedNetwork}
          allNetwork={network}
          selectedPattern={parseInt(p)}
          selectedRoute={selectedRoute}
          selectedStop={selectedStop}
        />
      </>
    )
  }
}

export default NetworkSchedules
