const config = {
  name: 'Trevo',
  operator: 'trevo',
  operatorID: 'EBUS',
  agencyID: 133,
  info: {
    email: 'geral@trevo.com.pt',
    phone: '+351 266 106 923',
    support_email: 'info@trevo.com.pt',
    support_phone: '+351 266 106 923',
    recruitment_email: 'recrutamento@rodalentejo.pt',
    copyright: false,
  },
  sections: {
    about: true, // About page
    ads: {
      mastercard: false,
      pass_migration: false,
      pick: true,
      trevo_fare_update: true,
      mobiazores_fare_update: false,
    },
    cookie_policy: true, // Cookies policy and cookie permission dialog
    faqs: true, // FAQs page
    funding: true, // Additional info below footer
    migration: false, // Pass migration page
    news: true, // News page
    pass_requests: true, // Enables requesting travel cards
    pass_renewal: true, // Enables renewing travel cards
    pass_requirements: 6, // Pass Requirements
    passenger_rights: true, // Passenger's rights page
    pass_request_policy: false, // Pass Request Data Protection Policy
    pass_migration: false, // Additional info before ticket rates
    pick: true, // Toggles all components related to the PICK app
    privacy_policy: true, // Privacy Policy page
    promotion: false, // Promotion page
    recruitment: {
      enabled: true, // Recruitment page
    },
    reviews: false, // Passenger reviews on the home page
    schedules: true, // PDF schedules
    support: true, // Customer Support page
    terms_conditions: true, // Terms and Conditions page
    ticket_agents: 4, // Ticket Agents component
    ticket_info: false, // Additional info before ticket rates
    ticket_rates: true, // Ticket Prices
    zone_map: false, // Zone Map for Routes to display in Tickets & Fres and Network & Schedules
  },
  mainTitleColor: { sm: '#475467', lg: '#475467' },
  mainColor: '#00A5B5',
  hoverColor: '#F5FDFD',
  progressStyle:
    'linear-gradient(to right, rgba(48, 187, 202, 0), rgba(48, 187, 202, 1), rgba(48, 187, 202, 1), rgba(48, 187, 202, 0))',
  themeTokens: {
    colors: {
      primaryA20: 'rgba(95, 208, 223, 0.20)',
      primaryA40: 'rgba(95, 208, 223, 0.40)',
      onPrimary: '#101828',
      primary25: '#F5FDFD',
      primary50: '#EBFAFB',
      primary100: '#CDF1F5',
      primary200: '#AFE8EF',
      primary300: '#87DCE7',
      primary400: '#5FD0DF',
      primary500: '#30BBCA',
      primary600: '#00A5B5',
      primary700: '#017682',
      primary800: '#004A51',
      primary900: '#001D20',
    },
  },
}

export default config
