import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Image,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteLabel } from '@ubirider/pick-component-library'
import warningCircleIcon from '../../../../assets/warning_circle.svg'

export function StopScheduleTable({ schedules, allNetwork, currentWeekday, isException }) {
  const { t } = useTranslation()

  const [sortedSchedules, setSortedSchedules] = useState([])

  const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']

  useEffect(() => {
    if (schedules && schedules.length > 0 && schedules.every(obj => obj?.time?.length > 0 || obj?.time !== undefined)) {
      const networkMap = allNetwork.routes.reduce((map, item) => {
        map[item.id] = item.shortName
        return map
      }, {})

      const sortedSchedules = schedules.map(schedule => {
        return { ...schedule, shortName: networkMap[schedule.routeID] }
      })

      setSortedSchedules(sortedSchedules.sort((a, b) => a.shortName.localeCompare(b.shortName, undefined, { numeric: true })))
    }
  }, [schedules])

  if (schedules.every(obj => obj?.time?.length === 0 || obj?.time === undefined)) {
    return (
      <Flex padding={{ sm: '36px' }} direction='column' gap='8px' align='center' justify='center' h={{ sm: '100%', lg: '304px' }} w='100%'>
        <Image src={warningCircleIcon} />
        <Text fontSize='20px' fontWeight='600' color='#98A2B3' textAlign='center'>
          {weekdays.includes(currentWeekday) && !isException
            ? t('network_schedule.schedules_stops_empty_state_weekdays')
            : currentWeekday === 'saturday' && !isException
              ? t('network_schedule.schedules_stops_empty_state_saturdays')
              : currentWeekday === 'sunday' && !isException
                ? t('network_schedule.schedules_stops_empty_state_holidays')
                : t('network_schedule.schedules_stops_empty_state_selected_date')}
        </Text>
      </Flex>
    )
  } else {
    return (
      <>
        <Flex display={{ sm: 'none', lg: 'flex' }} padding={{ sm: '16px', lg: '0 24px' }} gap='8px' h='100%' w='100%'>
          <Flex direction='column' align='flex-start'>
            {sortedSchedules.map((result, index) => {
              return (
                <Tooltip
                  hasArrow
                  bg='#475467'
                  rounded='4px'
                  placement='top-start'
                  maxW='calc(100vw)'
                  label={
                    <Flex direction='column'>
                      <Text textAlign='left' fontSize='13px' fontWeight='600' color='white' overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
                        {allNetwork.routes
                          .find(route => route.id === result.routeID)
                          ?.name.replace(/([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g, match => match.charAt(0) + match.slice(1).toLowerCase())}
                      </Text>
                      {result?.direction && (
                        <Flex gap='4px'>
                          <Text textAlign='left' fontSize='13px' fontWeight='700' color='white'>
                            {t('network_schedule.direction')}
                          </Text>
                          <Text textAlign='left' fontSize='13px' color='white'>
                            {result.direction.replace(/([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g, match => match.charAt(0) + match.slice(1).toLowerCase())}
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  }
                  aria-label={allNetwork.routes
                    .find(route => route.id === result.routeID)
                    ?.name.replace(/([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g, match => match.charAt(0) + match.slice(1).toLowerCase())}
                >
                  <Flex key={index} w='260px' h='48px' gap='16px' padding='8px 16px' pr='32px' align='center'>
                    <RouteLabel
                      shortName={allNetwork.routes.find(route => route.id === result.routeID)?.shortName}
                      color={allNetwork.routes.find(route => route.id === result.routeID)?.color}
                      textColor={allNetwork.routes.find(route => route.id === result.routeID)?.textColor}
                    />
                    <Flex direction='column' w='calc(100% - 40px)'>
                      <Text textAlign='left' fontSize='13px' fontWeight='600' color='#1D2939' overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
                        {allNetwork.routes
                          .find(route => route.id === result.routeID)
                          ?.name.replace(/([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g, match => match.charAt(0) + match.slice(1).toLowerCase())}
                      </Text>
                      {result?.direction && (
                        <Flex gap='4px'>
                          <Text textAlign='left' fontSize='13px' color='#98A2B3'>
                            {t('network_schedule.direction')}
                          </Text>
                          <Text textAlign='left' fontSize='13px' color='#1D2939' overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
                            {result.direction.replace(/([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g, match => match.charAt(0) + match.slice(1).toLowerCase())}
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Tooltip>
              )
            })}
          </Flex>
          <Flex bg='linear-gradient(90deg, rgba(217, 217, 217, 0.50) 18.75%, rgba(217, 217, 217, 0.00) 100%)' h={'calc(' + schedules.length + '* 48px)'} w='8px'></Flex>
          <Flex direction='column' align='flex-start' w='100%' overflowX='auto'>
            {schedules &&
              sortedSchedules &&
              sortedSchedules.map((result, index) => {
                return (
                  <Flex key={index} w='100%' h='48px' padding='10px 0' align='center' borderRadius='0 8px 8px 0'>
                    <Text textAlign='left' fontSize='13px' fontWeight='600' color='#1D2939'>
                      {result?.time !== [] ? result?.time?.map(time => time.slice(0, 5)).join('\u00A0\u00A0\u00A0\u00A0') : '-'}
                    </Text>
                  </Flex>
                )
              })}
          </Flex>
        </Flex>

        <Flex direction='column' gap='8px' display={{ sm: 'flex', lg: 'none' }} padding={{ sm: '16px', lg: '0px 24px 16px 24px' }}>
          <Text fontSize='14px' fontWeight='700' color='#424D5F' textAlign='left'>
            {t('network_schedule.routes')}
          </Text>
          <Accordion w='100%' allowToggle>
            {sortedSchedules.map((result, index) => {
              return (
                <AccordionItem key={index} rounded='4px' w='100%' border='none'>
                  {({ isExpanded }) => (
                    <Flex direction='column' w='100%' rounded='4px'>
                      <AccordionButton padding='8px' w='100%' bg={isExpanded ? '#F2F4F7' : 'white'}>
                        <Flex justifyContent='space-between' align='center' w='100%'>
                          <Flex gap='8px' w='90%'>
                            <RouteLabel
                              shortName={allNetwork.routes.find(route => route.id === result.routeID)?.shortName}
                              color={allNetwork.routes.find(route => route.id === result.routeID)?.color}
                              textColor={allNetwork.routes.find(route => route.id === result.routeID)?.textColor}
                            />
                            <Text
                              fontSize='14px'
                              fontWeight={isExpanded ? '700' : '400'}
                              color='#1D2939'
                              textAlign='left'
                              overflow='hidden'
                              textOverflow='ellipsis'
                              whiteSpace='nowrap'
                            >
                              {allNetwork.routes
                                .find(route => route.id === result.routeID)
                                ?.name.replace(/([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g, match => match.charAt(0) + match.slice(1).toLowerCase())}
                            </Text>
                          </Flex>
                          <AccordionIcon color='#344054' />
                        </Flex>
                      </AccordionButton>
                      <AccordionPanel rounded='4px' bg={isExpanded ? '#F2F4F7' : 'white'}>
                        <Flex direction='column' flex='1' p='8px 16px' gap='8px'>
                          {result?.direction && (
                            <Flex gap='8px'>
                              <Text fontSize='13px' color='#424D5F'>
                                {t('network_schedule.direction')}
                              </Text>
                              <Text fontSize='13px' fontWeight='700' color='#282828'>
                                {result?.direction?.replace(/([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g, match => match.charAt(0) + match.slice(1).toLowerCase())}
                              </Text>
                            </Flex>
                          )}
                          {result?.time !== [] ? (
                            result?.time?.map(time => {
                              return (
                                <Text fontSize='13px' color='#282828'>
                                  {time.slice(0, 5)}
                                </Text>
                              )
                            })
                          ) : (
                            <Text fontSize='13px' color='#282828'>
                              -
                            </Text>
                          )}
                        </Flex>
                      </AccordionPanel>
                    </Flex>
                  )}
                </AccordionItem>
              )
            })}
          </Accordion>
        </Flex>
      </>
    )
  }
}
